import "./Login.css";
import LoginForm from "../components/LoginForm";

function Login() {
  return (
    <div className="login container">
      <LoginForm />
    </div>
  );
}

export default Login;
