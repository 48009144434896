import { ReactElement } from "react";
import { useLocation } from "react-router-dom";

interface SubTabProps {
  pathname: string;
}

function SubTabs({ pathname }: SubTabProps) {
  const currentRoute = pathname.split("/")[2];
  const currentSubRoute = pathname.split("/")[3];

  function onActive(tabName: string) {
    if (currentSubRoute === tabName) {
      return " active";
    }

    return "";
  }

  switch (currentRoute) {
    case "editor":
      return (
        <div className="console subtab-container">
          <a
            className={"console subtab" + onActive("summary")}
            href="/console/editor/summary"
          >
            {onActive("summary") && <div className="console selected-bar" />}
            <div className="console small-icon" />
            Summary
          </a>
          <a
            className={"console subtab" + onActive("setup")}
            href="/console/editor/setup"
          >
            {onActive("setup") && <div className="console selected-bar" />}
            <div className="console small-icon" />
            Setup
          </a>
          <a
            className={"console subtab" + onActive("steps")}
            href="/console/editor/steps"
          >
            {onActive("steps") && <div className="console selected-bar" />}
            <div className="console small-icon" />
            Steps
          </a>
          <a
            className={"console subtab" + onActive("versions")}
            href="/console/editor/versions"
          >
            {onActive("versions") && <div className="console selected-bar" />}
            <div className="console small-icon" />
            Versions
          </a>
          <a
            className={"console subtab" + onActive("settings")}
            href="/console/editor/settings"
          >
            {onActive("settings") && <div className="console selected-bar" />}
            <div className="console small-icon" />
            Settings
          </a>
        </div>
      );
    default:
      return <></>;
  }
}

function ConsoleSidebar() {
  const location = useLocation();
  const currentRoute = location.pathname.split("/");

  function generateTab(tabName: string): ReactElement {
    let activeClass = "";
    let subTabs = <></>;
    if (currentRoute[2] === tabName.toLowerCase()) {
      activeClass = " active";
      subTabs = <SubTabs pathname={location.pathname} />;
    }

    return (
      <div className={"console sidetab" + activeClass}>
        <a
          className="console sidetab title"
          href={"/console/" + tabName.toLowerCase()}
        >
          <div className="console large-icon" />
          {tabName}
        </a>
        {subTabs}
      </div>
    );
  }

  return (
    <div className="console sidebar">
      {generateTab("Overview")}
      {generateTab("Brokers")}
      {generateTab("Editor")}
      {generateTab("Live")}
      {generateTab("Backtests")}
    </div>
  );
}

export default ConsoleSidebar;
