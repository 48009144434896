import "./Console.css";
import logo from "../logo.svg";
import { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import ConsoleSidebar from "../components/ConsoleSidebar";
import Overview from "../components/Overview";
import Brokers from "../components/Brokers";
import Live from "../components/Live";
import Editor from "../components/editor/Editor";
import Backtests from "../components/Backtests";

function Console() {
  const location = useLocation();
  const currentRoute = location.pathname.split("/");

  function getContent() {
    switch (currentRoute[2]) {
      case "overview":
        return <Overview />;
      case "brokers":
        return <Brokers />;
      case "editor":
        return <Editor />;
      case "live":
        return <Live />;
      case "backtests":
        return <Backtests />;
      default:
        return <Navigate to="/console/overview" />;
    }
  }

  useEffect(() => {
    const cookies = new Cookies();
    async function check_auth() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: cookies.get("session"),
        },
      };
      const response = await fetch(
        "https://api.dev.algowolf.com/user/cognito_user",
        requestOptions
      );

      if (response.status >= 400) {
        window.location.replace("/login");
      }
    }

    check_auth();
  }, []);

  // If path is `/console` navigate to overview tab
  if (currentRoute.length < 3) {
    return <Navigate to="/console/overview" />;
  }

  return (
    <div className="console container">
      <nav>
        <a className="console logo-wrapper" href="/">
          <img src={logo} alt="logo" />
        </a>
      </nav>
      <div className="console main">
        <ConsoleSidebar />
        <div className="console content">{getContent()}</div>
      </div>
    </div>
  );
}

export default Console;
