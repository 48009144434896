import { Navigate, useLocation } from "react-router-dom";
import Settings from "./Settings";
import Setup from "./Setup";
import Steps from "./Steps";
import Summary from "./Summary";
import Versions from "./Versions";

function Editor() {
  const location = useLocation();
  const currentRoute = location.pathname.split("/");

  function getContent() {
    switch (currentRoute[3]) {
      case "summary":
        return <Summary />;
      case "setup":
        return <Setup />;
      case "steps":
        return <Steps />;
      case "versions":
        return <Versions />;
      case "settings":
        return <Settings />;
      default:
        return <Navigate to="/console/editor/summary" />;
    }
  }

  if (currentRoute.length < 4) {
    return <Navigate to="/console/editor/summary" />;
  }

  return getContent();
}

export default Editor;
