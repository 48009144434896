import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Console from "./pages/Console";
import Login from "./pages/Login";
import Logout from "./pages/Logout";

function App() {
  return (
    <div className="app">
      <header className="app header"></header>
      <section className="app body">
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/console/*" element={<Console />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        </BrowserRouter>
      </section>
    </div>
  );
}

export default App;
