import { useEffect } from "react";
import Cookies from "universal-cookie";

function Logout() {
  useEffect(() => {
    const cookies = new Cookies();
    cookies.remove("session", { path: "/" });
    window.location.replace("/login");
  }, []);

  return <></>;
}

export default Logout;
