import logo from "../logo.svg";
import { useEffect, useState } from "react";
import Cookies from "universal-cookie";

function LoginForm() {
  const cookies = new Cookies();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  async function handleSubmit(event: any) {
    event.preventDefault();
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    };
    const response = await fetch(
      "https://api.dev.algowolf.com/user/login",
      requestOptions
    );
    if (response.status === 200) {
      const data = await response.json();
      cookies.set("session", data.access_token, { path: "/" });
      window.location.replace("/console");
    }
  }

  function handleChange(event: any) {
    if (event.target.name === "email") {
      setEmail(event.target.value);
    } else if (event.target.name === "password") {
      setPassword(event.target.value);
    }
  }

  useEffect(() => {
    async function check_auth() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: cookies.get("session"),
        },
      };
      const response = await fetch(
        "https://api.dev.algowolf.com/user/cognito_user",
        requestOptions
      );

      if (response.status === 200) {
        window.location.replace("/console");
      }
    }

    check_auth();
  });

  return (
    <form className="login form" onSubmit={handleSubmit}>
      <a className="login logo-wrapper" href="/">
        <img src={logo} alt="logo" />
      </a>
      <label>Email</label>
      <input
        className="login field"
        type="email"
        name="email"
        placeholder="laura@email.com"
        onChange={handleChange}
      />
      <label>Password</label>
      <input
        className="login field"
        type="password"
        name="password"
        placeholder="Enter your password"
        onChange={handleChange}
      />
      <input className="login submit" type="submit" value="Login" />
    </form>
  );
}

export default LoginForm;
